$screen-lm: 1127px;
$screen-tablet: 933px;
$screen-mobile: 767px;
@font-face {font-family: 'dinpro-light';src: url("../fonts/dinpro-light.otf") format("opentype"); font-style: normal;font-weight: normal;}
@font-face {font-family: 'dinpro';src: url("../fonts/dinpro.otf") format("opentype"); font-style:normal;font-weight:normal;}
@font-face {font-family: 'dinpro-medium';src: url("../fonts/dinpro-medium.otf") format("opentype"); font-style: normal;font-weight: normal;}
@font-face {font-family: 'dinpro-bold';src: url("../fonts/dinpro-bold.otf") format("opentype"); font-style: normal;font-weight: normal;}

$fontDin : 'dinpro','sans-serif';
$fontDinMedium : 'dinpro-medium','sans-serif';
$fontDinBold : 'dinpro-bold','sans-serif';
$fontDinLight : 'dinpro-light','sans-serif';
$basecolor: #28166f;
$fontColor: #3D3D3D;
html,body{background-color:$basecolor;font-size:62.5%;font-family:$fontDin;color:$basecolor;}
.parallax-mirror {z-index:0 !important}
header{background-color:#FFF;
    .ui.grid{margin:0;}
    .mobile.only{
        .ui.menu{
            div.item:before{background:none !important;}
            ul{
                li.item{
                    &:before{background:none !important;}
                    &.active{font-family: $fontDinBold;color:$basecolor;background:none;border-bottom:3px solid $basecolor;
                        a{font-family:$fontDinBold;}
                    }
                    a{font-size:1.5rem;}
                }
            }
        }
        .logo{border-bottom:none !important;}
        .dropdown i{font-size:3rem;margin:3rem 0 0 3rem;}
        @media screen and (max-width:$screen-mobile){
            .ui.dropdown .menu{left:-7.3rem}
            .ui.six.wide.column{text-align:right;}
        }
    }
    .ui.menu.fixed{padding:0;margin:0;border:none;border:none;font-family:$fontDin;box-shadow:none;
        div{
            &.item{position:inherit;padding-left:0;border-left:0 !important;}
        }
        ul {
            li.item{padding:0;color:$basecolor;margin:0;font-size:1.6rem;border-bottom:7px solid #FFF;
                &:before{background:none;}
                &:hover{font-family:$fontDinBold;color:#D4D4D4;background:none;border-bottom:7px solid #D4D4D4;}
                &.active{font-family: $fontDinBold;color:$basecolor;background:none;border-bottom:7px solid $basecolor;
                    a{font-family:$fontDinBold;}
                }
                a{padding:2rem 1rem;font-family: $fontDinMedium;color:$basecolor;
                    &:hover{font-family:$fontDinMedium;color:#D4D4D4;}
                }
            }
            @media screen and (max-width:$screen-tablet){
                li.item{padding:0;color:$basecolor;margin:0;font-size:1rem;border-bottom:7px solid #FFF;
                    &:before{background:none;}
                    &:hover{font-family:$fontDinBold;color:#D4D4D4;background:none;border-bottom:7px solid #D4D4D4;}
                    &.active{font-family: $fontDinBold;color:$basecolor;background:none;border-bottom:7px solid $basecolor;
                        a{font-family:$fontDinBold;}
                    }
                    a{padding:2rem 1rem;font-family: $fontDinMedium;color:$basecolor;
                        &:hover{font-family:$fontDinMedium;color:#D4D4D4;}
                    }
                }
            }
        }
    }
}
main{background-color:#FFF;margin-top:4.7rem;
    .ui.fixed.bottom.sticky.circle{right:1%;bottom:2%;cursor: pointer;
        .huge.chevron.circle.up.icon{color:#D4D4D4;}
    }
    #home{background: transparent;transform:initial !important;
        #banner{position:relative;background: transparent;
            .parallax-banner {min-height: 53rem;background: transparent;width:100%;}
            .text{position:absolute;font-family:$fontDinMedium;top:15rem;left:50rem;max-width:40rem;font-size:2.3rem;background:transparent;
                h5{color:#FFF;font-size:1.5rem;}
            }
            @media screen and (max-width:$screen-tablet){
                .text{position:absolute;font-family:$fontDinMedium;top:10rem;left:35rem;max-width:20rem;font-size:1.8rem;background:transparent;
                    h5{color:#FFF;font-size:1.5rem;}
                }
            }
            @media screen and (max-width:$screen-mobile){
                .text{position:absolute;font-family:$fontDinMedium;top:10rem;left:10rem;max-width:20rem;font-size:1.5rem;background:transparent;
                    h5{color:#FFF;font-size:1.2rem;}
                }
            }
        }
        #why{background-color:#FFF;margin-top:4rem;
            h2{font-size:4.5rem;font-family:$fontDinBold;}
            p{font-size:2rem;color:#000;}
            button{border:1px solid $basecolor;background:none;color:$basecolor;font-size:1.7rem;padding:1rem 2rem;margin-bottom:4rem;
                   &:hover{background-color:$basecolor;border: 1px solid #FFF;color:#FFF;}
            }
            @media screen and (max-width:$screen-tablet){
                h2{font-size:3.5rem;font-family:$fontDinBold;}
                p{font-size:1.5rem;color:#000;}
                button{border:1px solid $basecolor;background:none;color:$basecolor;font-size:1.1rem;padding:1rem 2rem;margin-bottom:4rem;
                       &:hover{background-color:$basecolor;border: 1px solid #FFF;color:#FFF;}
                }
            }
            @media screen and (max-width:$screen-mobile){
                h2{font-size:3.5rem;font-family:$fontDinBold;}
                p{font-size:1.5rem;color:#000;}
                button{border:1px solid $basecolor;background:none;color:$basecolor;font-size:1.1rem;padding:1rem 2rem;margin-bottom:4rem;
                       &:hover{background-color:$basecolor;border: 1px solid #FFF;color:#FFF;}
                }
            }
            #about{
                .history{position:relative;min-height:50rem;margin-top:8rem;
                    .ui.rail{width:auto;
                        &.right{left:95%;z-index:1;margin:0;padding:0;
                            i{padding-top:2rem;}
                        }
                        @media screen and (max-width:$screen-tablet){
                            &.right{left:92%;top:0;z-index:1;margin:0;padding:0;
                                i{padding-top:1rem;}
                            }
                        }
                        @media screen and (max-width:$screen-mobile){
                            &.right{left:85%;top:0;margin-top:2rem;z-index:1;margin:0;padding:0;
                                i{padding-top:1rem;}
                            }
                        }
                        .ui.sticky.fixedclose{cursor: pointer;left:auto !important;
                            i.huge.remove.circle.outline.icon{color:#D4D4D4;}
                        }
                        @media screen and (max-width:$screen-mobile){
                            .ui.sticky.fixedclose{top:1rem;}
                        }
                    }
                    h2{font-family:$fontDinBold;font-size:3.5rem;color:$basecolor;margin-top:6rem;}
                    p{font-family:$fontDin;font-size:1.5rem;color:#000;}
                }
                .vision{padding:4rem 0;background-color:$basecolor;color:#FFF;position:relative;
                    h2{font-family:$fontDinBold;font-size:3.5rem;}
                    p{font-family:$fontDin;font-size:1.5rem;max-width:50rem;color:#FFF;}
                }
                .values{background-color:#CCC;padding:4rem 0;
                    h2{font-family:$fontDinBold;font-size:3.5rem;}
                    p{font-size:1.5rem;font-family:$fontDin;color:#000;}
                    ul{padding:0 1.4rem;margin:0;
                        li{font-size:1.5rem;font-family:$fontDin;color:#000;padding:0.5rem 0;line-height:2rem;
                           b,strong{font-family:$fontDinBold;}
                        }
                    }
                }
                @media screen and (max-width:$screen-tablet){
                    .history{position:relative;min-height:43.5rem;margin-top:4rem;
                        h2{font-family:$fontDinBold;font-size:3.2rem;color:$basecolor;margin-top:4rem;}
                        p{font-family:$fontDin;font-size:1.2rem;color:#000;}
                    }
                    .vision{padding:4rem 0;background-color:$basecolor;color:#FFF;position:relative;
                        h2{font-family:$fontDinBold;font-size:3.2rem;}
                        p{font-family:$fontDin;font-size:1.2rem;max-width:38rem;color:#FFF;}
                    }
                    .values{background-color:#CCC;padding:4rem 0;
                        h2{font-family:$fontDinBold;font-size:3.2rem;}
                        p{font-size:1.2rem;font-family:$fontDin;color:#000;}
                        ul{padding:0 1.4rem;margin:0;
                            li{font-size:1.2rem;font-family:$fontDin;color:#000;padding:0.5rem 0;
                               b,strong{font-family:$fontDinBold;}
                            }
                        }
                    }
                }
                @media screen and (max-width:$screen-mobile){
                    .history{position:relative;min-height:43.5rem;margin-top:4rem;
                        h2{font-family:$fontDinBold;font-size:3.2rem;color:$basecolor;margin-top:4rem;}
                        p{font-family:$fontDin;font-size:1.2rem;color:#000;}
                    }
                    .vision{padding:4rem 0;background-color:$basecolor;color:#FFF;position:relative;
                        h2{font-family:$fontDinBold;font-size:3.2rem;}
                        p{font-family:$fontDin;font-size:1.2rem;max-width:38rem;color:#FFF;}
                    }
                    .values{background-color:#CCC;padding:4rem 0;
                        h2{font-family:$fontDinBold;font-size:3.2rem;}
                        p{font-size:1.2rem;font-family:$fontDin;color:#000;}
                        ul{padding:0 1.4rem;margin:0;
                            li{font-size:1.2rem;font-family:$fontDin;color:#000;padding:0.5rem 0;
                               b,strong{font-family:$fontDinBold;}
                            }
                        }
                    }
                }
            }
        }
        #newsfeed{background-color:$basecolor;padding:2rem 0 2rem 0;color:#FFF;position:relative;
            button{background:none;color:#FFF;border:1px solid #FFF;padding:1rem 2rem;margin-left:1.5rem;font-size:1.5rem;
                   &:hover{background-color:#FFF;border: 1px solid $basecolor;color:$basecolor;}
            }
            p{font-size:1.5rem;}
            i{
                &.icon{margin-left:1rem;font-size:1.5rem;}
            }
            @media screen and (max-width:$screen-tablet){
                button{background:none;color:#FFF;border:1px solid #FFF;padding:1rem 2rem;margin-left:1.5rem;font-size:1.2rem;
                   &:hover{background-color:#FFF;border: 1px solid $basecolor;color:$basecolor;}
                }
                p{font-size:1.2rem;}
                i{
                    &.icon{margin-left:1rem;font-size:1.2rem;}
                }
            }
            @media screen and (max-width:$screen-mobile){
                button{background:none;color:#FFF;border:1px solid #FFF;padding:1rem 2rem;margin-left:1.5rem;font-size:1.2rem;
                   &:hover{background-color:#FFF;border: 1px solid $basecolor;color:$basecolor;}
                }
                p{font-size:1.2rem;}
                i{
                    &.icon{margin-left:1rem;font-size:1.2rem;}
                }
            }
            
        }
        
        #offering{
            .offer{position:relative;
                   .slideshow{
                       .slick-prev{left:2%;z-index:10;}
                       .slick-next{left:96%;}
                   }
                .offer_info{position:absolute; bottom:7%;left:12%;
                    h2{font-family:$fontDinBold;font-size:3.5rem;margin-bottom:2rem;color:#FFF;max-width:48rem;margin-top:20rem;}
                    p{font-family:$fontDinLight;font-size:2.5rem;color:#FFF;max-width:70rem;line-height:3.5rem;}
                    button{background:none;border:1px solid #FFF;margin-top:2rem;padding:1rem 2rem;color:#FFF;font-family: $fontDin;font-size:1.5rem;
                        &:hover{background-color:#FFF;border: 1px solid $basecolor;color:$basecolor;}
                    }
                }
                @media screen and (max-width:$screen-tablet){
                    .offer_info{position:absolute; bottom:8%;left:12%;
                        h2{font-family:$fontDinBold;font-size:3.5rem;margin:2rem 0;color:#FFF;max-width:48rem;}
                        p{font-family:$fontDinLight;font-size:2rem;line-height:3rem;color:#FFF;max-width:45rem;margin:0;padding:0}
                        button{background:none;border:1px solid #FFF;margin-top:1rem;padding:1rem;color:#FFF;font-family: $fontDin;font-size:1.3rem;
                            &:hover{background-color:#FFF;border: 1px solid $basecolor;color:$basecolor;}
                        }
                    }
                }
                @media screen and (max-width:$screen-mobile){
                    .slideshow{
                       .slick-prev{left:2%;z-index:10;}
                       .slick-next{left:94%;}
                    }
                    .offer_info{position:absolute; bottom:10%;left:10%;
                        h2{font-family:$fontDinBold;font-size:3rem;margin:0.5rem 0;color:#FFF;max-width:45rem;}
                        p{font-family:$fontDinLight;font-size:1.2rem;line-height:1.3rem;color:#FFF;max-width:25rem;margin:0;padding:0}
                        button{background:none;border:1px solid #FFF;margin-top:1rem;padding:1rem;color:#FFF;font-family: $fontDin;font-size:1rem;
                            &:hover{background-color:#FFF;border: 1px solid $basecolor;color:$basecolor;}
                        }
                    }
                }
            }
            #solution{padding:4rem 0;background-color:#F4F4F4;position:relative;
                .ui.rail{width:auto;
                    &.right{left:92%;z-index:1;margin:0;padding:0;}
                    @media screen and (max-width:$screen-tablet){
                       &.right{left:90%;z-index:1;margin:0;padding:0;}
                    }
                    @media screen and (max-width:$screen-mobile){
                        &.right{left:83%;z-index:1;margin:0;padding:0;}
                    }
                    .ui.sticky.fixedclose{cursor: pointer;left:auto !important;
                        i.huge.remove.circle.outline.icon{color:#D4D4D4;}
                    }
                    @media screen and (max-width:$screen-mobile){
                        .ui.sticky.fixedclose{top:1rem;}
                    }
                }
                h1{font-family:$fontDinBold;font-size:3.5rem;}
                h2{font-family:$fontDinBold;font-size:1.7rem;color:#000;}
                p{font-family:$fontDin;font-size:1.5rem;color:#000;}
                ul{padding:8rem 0 0 6rem;margin:0;
                    li{font-size:1.5rem;font-family:$fontDinBold;color:$basecolor;padding:0.2rem 0;line-height:2rem;
                        b{font-family:$fontDinBold;}
                    }
                }
                @media screen and (max-width:$screen-tablet){
                    ul{
                       li{line-height:2rem;}
                    }
                }
                @media screen and (max-width:$screen-mobile){
                    ul{padding:0 0 0 2rem;
                       li{line-height:2rem;}
                    }
                }
                .service{border-top:2px solid $basecolor;padding-top:2rem;margin-top:2rem;
                    ul{padding:5rem 0 0 6rem;}
                    @media screen and (max-width:$screen-mobile){
                        ul{padding:0 0 0 2rem;}
                    }
                }
            }
        }
        #principal{padding:4rem 0 2rem 0;
            h1{font-family: $fontDinBold;font-size:3.5rem;text-align: center}
            p{font-size:1.5rem;text-align: center;color:#000;}
            @media screen and (max-width:$screen-tablet){
                h1{font-size:3.5rem;}
                p{font-size:1.5rem;text-align: center;color:#000;}
            }
            .img{position:initial;
                .ui.dropdown{position:initial;
                    &.active{
                        img{background-color:#e9e7f0;margin:0 auto;}
                    }
                    &:hover{
                        img{background-color:#e9e7f0;margin:0 auto;}
                    }
                    .menu{min-width:425%;position:relative;top:0;margin:0 auto;padding:0;color:#FFF;white-space:normal;border:none !important;border-radius:none !important;box-shadow:none !important;
                        &.tab1{left:-106%;}
                        &.tab2{left:-219%;}
                        &.tab3{left:-325%;}  
                        .message{background-color:#28166f;color:#FFF;white-space:normal;text-align:center;padding:2rem 1rem;margin:0;border-radius:none !important;box-shadow:none !important;border:none !important;
                            p{color:#FFF;font-size:1.5rem;font-family:$fontDin;margin-bottom:1.5rem;}
                            a{border:1px solid #FFF;padding:0.5rem 1rem;color:#FFF;font-size:1.3rem;
                              &:hover{background-color:#FFF;color:$basecolor;}
                            }
                        }
                    }
                    @media screen and (max-width:$screen-tablet){
                        .menu{position:relative;top:0;margin:0 auto;min-width:76%;max-width:76%;padding:0;color:#FFF;white-space:normal;border:none !important;border-radius:none !important;box-shadow:none !important;
                            &.tab1{left:0;}
                            &.tab2{left:0;}
                            &.tab3{left:0;}  
                            .message{min-width:100%;max-width:100%;background-color:#28166f;color:#FFF;white-space:normal;text-align:center;padding:2rem 1rem;margin:0;border-radius:none !important;box-shadow:none !important;border:none !important;
                                p{color:#FFF;font-size:1.3rem;font-family:$fontDin;margin-bottom:1.5rem;}
                                a{border:1px solid #FFF;padding:0.5rem 1rem;color:#FFF;
                                  &:hover{background-color:#FFF;color:$basecolor;}
                                }
                            }
                        }
                    }
                    @media screen and (max-width:$screen-mobile){
                        .menu{position:relative;top:0;margin:0 auto;min-width:100%;max-width:100%;padding:0;color:#FFF;white-space:normal;border:none !important;border-radius:none !important;box-shadow:none !important;
                            &.tab1{left:0;}
                            &.tab2{left:0;}
                            &.tab3{left:0;}  
                            .message{min-width:100%;max-width:100%;background-color:#28166f;color:#FFF;white-space:normal;text-align:center;padding:2rem 1rem;margin:0;border-radius:none !important;box-shadow:none !important;border:none !important;
                                p{color:#FFF;font-size:1.3rem;font-family:$fontDin;margin-bottom:1.5rem;}
                                a{border:1px solid #FFF;padding:0.5rem 1rem;color:#FFF;
                                  &:hover{background-color:#FFF;color:$basecolor;}
                                }
                            }
                        }
                    }
                }
            }
        }
        #contact{padding:4rem 0;background-color:#F7F7F7;
            h1{font-family:$fontDinBold;font-size:3.5rem;text-align: center;}
            address{font-style: normal;color:#000;font-size:1.5rem;margin-top:1rem;text-align:left;line-height:2rem;}
            @media screen and (max-width:$screen-tablet){
                address{text-align:center;font-size:1.5rem;line-height:2rem;}
            }
            @media screen and (max-width:$screen-mobile){
                address{text-align:center;font-size:1.5rem;line-height:2rem;}
            }
            .form{
                label{font-family:$fontDinBold;color:#000;font-size:1.5rem;text-align:left;padding:0.5rem 0;}
                input,textarea{background-color:#E5E5E5;font-size:1.5rem;}
                button{border:1px solid $basecolor;background:none;color:$basecolor;font-size:1.5rem;padding:1rem 2rem;
                       &:hover{background-color:$basecolor;border: 1px solid #FFF;color:#FFF;}
                }
                @media screen and (max-width:$screen-tablet){
                    label{font-family:$fontDinBold;color:#000;font-size:1.5rem;text-align:left;padding:0.5rem 0;}
                    input,textarea{background-color:#E5E5E5;font-size:1.5rem;}
                    button{border:1px solid $basecolor;background:none;color:$basecolor;font-size:1.5rem;padding:1rem 2rem;
                           &:hover{background-color:$basecolor;border: 1px solid #FFF;color:#FFF;}
                    }
                }
                @media screen and (max-width:$screen-mobile){
                    label{font-family:$fontDinBold;color:#000;font-size:1.5rem;text-align:left;padding:0.5rem 0;}
                    input,textarea{background-color:#E5E5E5;font-size:1.5rem;}
                    button{border:1px solid $basecolor;background:none;color:$basecolor;font-size:1.5rem;padding:1rem 2rem;
                           &:hover{background-color:$basecolor;border: 1px solid #FFF;color:#FFF;}
                    }
                }
            }
        }
    }
    #news{background-color:#f4f4f4;
        .content{padding:8rem 0;
            h1{font-size:4rem;font-family:$fontDinBold;padding:1rem 0 2rem 0;}
            h2{font-size:2rem;font-family:$fontDinBold;color:#202020;
               a{color:#000;}
            }
            p{font-size:1.5rem;color:#202020;}
            @media screen and (max-width:$screen-mobile){
                img{text-align:center;margin:0 auto;}    
            }
        }
        .rss{background-color:#dfdde6;padding:4rem 0;
             h3{font-size:2rem;color:#202020;}
             p{font-size:1.5rem;color:#202020;}
        }
    }
    #updates{
        .banner{background-color:#f4f4f4;padding:4rem 0;
            h1{font-size:3.5rem;font-family: $fontDinBold;}
            p{font-size:1.5rem;color:$fontColor;}
        }
        .content{padding:4rem 0;
            h2{font-family:$fontDinBold;font-size:200%;color:$fontColor;}
            .position{border-bottom:2px solid #D8D8D8;padding:1rem 0;
                h3{font-family:$fontDinMedium;font-size:1.5rem;color:$fontColor;}
                p{font-size:1.2rem;color:$fontColor;}
            }
            .sendcv{padding-left:1rem;border-left:2px solid #D8D8D8;
                label{font-family:$fontDinMedium;}
                button{border:1px solid $basecolor;background:none;color:$basecolor;font-size:1.2rem;padding:0.5rem 1rem;
                    &:hover{background-color:$basecolor;border: 1px solid #FFF;color:#FFF;}
                }
            }
        }
    }
    #career{
        .banner{background-color:#f4f4f4;padding:8rem 0;
            h1{font-size:5rem;font-family: $fontDinBold;}
            p{font-size:2.2rem;color:$fontColor;}
        }
        p{font-size:1.5rem;color:$fontColor;}
        .content{padding:4rem 0;
            h2{font-family:$fontDinBold;font-size:3rem;color:$fontColor;}
            .position{border-bottom:2px solid #D8D8D8;padding:1rem 0;
                &:last-child{border:none;}
                h3{font-family:$fontDinMedium;font-size:2rem;color:$fontColor;}
                p{font-size:1.5rem;color:$fontColor;}
            }
            .sendcv{padding-left:1rem;border-left:2px solid #D8D8D8;
                label{font-family:$fontDinMedium;font-size:1.5rem;line-height:2rem;}
                button{border:1px solid $basecolor;background:none;color:$basecolor;font-size:1.5rem;padding:0.5rem 1rem;
                    &:hover{background-color:$basecolor;border: 1px solid #FFF;color:#FFF;}
                }
            }
        }
    }
}
footer{margin:2% 0;
    @media screen and (max-width:$screen-mobile){
        .ui.stackable.two.column.grid{text-align:center;margin:0 auto;
            .ui.right.aligned.floated.column{text-align:center;}
        }
    }
    p{color:#FFF;font-size:1.2rem;}
    a{color:#FFF;
      &:hover{color:#FFFF00;}
    }
}